import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  makeProfilePrivate,
  makeProfilePublic,
  unblockMember,
  updateSlug,
} from './thunk';
import { State } from './slice';
import { ProfilePrivacyStatus } from '../../../types';

export const extraReducers = (builder: ActionReducerMapBuilder<State>) => {
  builder
    .addCase(makeProfilePrivate.fulfilled, (state) => {
      state.privacyStatus = ProfilePrivacyStatus.PRIVATE;
    })
    .addCase(makeProfilePublic.fulfilled, (state) => {
      state.privacyStatus = ProfilePrivacyStatus.PUBLIC;
    })
    .addCase(updateSlug.fulfilled, (state, { payload }) => {
      state.profile.slug = payload;
    })
    .addCase(unblockMember.fulfilled, (state, { payload }) => {
      state.blockedMembers = state.blockedMembers?.filter(
        (member) => member.id !== payload,
      );
    });
};
