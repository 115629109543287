import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../../../types';
import * as accountApi from '../../../server/account.api';
import { PrivacyStatusStatus } from '@wix/ambassador-members-ng-api/types';

import { unblockMember as unblockMemberApi } from '../../../server/account.api';

export const makeProfilePrivate = createAsyncThunk<void, void, ThunkApiConfig>(
  'member/makeProfilePrivate',
  async (_, { extra }) => {
    const { flowAPI, profileInfoChangeSubject } = extra;
    await flowAPI.httpClient.request(accountApi.leaveCommunity());
    profileInfoChangeSubject?.notifyObservers({
      profilePrivacy: PrivacyStatusStatus.PRIVATE,
    });
  },
);

export const makeProfilePublic = createAsyncThunk<void, void, ThunkApiConfig>(
  'member/makeProfilePublic',
  async (_, { extra }) => {
    const { flowAPI, profileInfoChangeSubject } = extra;
    await flowAPI.httpClient.request(accountApi.joinCommunity());
    profileInfoChangeSubject?.notifyObservers({
      profilePrivacy: PrivacyStatusStatus.PUBLIC,
    });
  },
);

export const updateSlug = createAsyncThunk<string, string, ThunkApiConfig>(
  'member/updateSlug',
  async (newSlug, { rejectWithValue, extra }) => {
    const { flowAPI } = extra;

    try {
      const { data } = await flowAPI.httpClient.request(
        accountApi.updateSlug(newSlug),
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const unblockMember = createAsyncThunk<string, string, ThunkApiConfig>(
  'members/unblockMember',
  async (memberId, { extra, rejectWithValue }) => {
    const { flowAPI } = extra;
    try {
      await flowAPI.httpClient.request(unblockMemberApi(memberId));
      return memberId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
